import { useLocation } from "react-router";

const useGetDigitalAssetId = (): string | null => {
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const returnUrlParam = urlSearchParams.get("ReturnUrl");

  if (!returnUrlParam) {
    return urlSearchParams.get("clientId");
  }

  const returnUrl = new URL(returnUrlParam);

  const returnUrlSearchParams = new URLSearchParams(returnUrl.search);

  const clientId = returnUrlSearchParams.get("client_id");

  return clientId;
};

export default useGetDigitalAssetId;
