import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import React, { useEffect, useState, useCallback, useContext } from "react";

import AlertRealOtps from "../../components/alert-real-otps/alert-real-otps";
import CredentialsForm from "../../components/continente-credentials/Form/Form";
import CredentialsFooter from "../../components/continente-credentials/footer/Footer";
import CredentialsHeader from "../../components/continente-credentials/header/Header";
import MantraButton from "../../components/continente-credentials/mantra-button/MantraButton";
import ErrorMessage from "../../components/continente-credentials/error-message/error-message";
import UsernameInput from "../../components/continente-credentials/username-input/UsernameInput";

import { FormError } from "../../models/continente-credentials/common/FormError";
import { UserSignIn } from "../../models/continente-credentials/mobile/UserSignIn";
import { DigitalAsset } from "../../models/continente-credentials/common/DigitalAsset";
import { UserSignInResponse } from "../../models/continente-credentials/mobile/UserSignInResponse";

import { userSignInService } from "../../services/continente-credentials/mobile/UserSignInService";
import { digitalAssetService } from "../../services/continente-credentials/common/DigitalAssetService";

import useGetDigitalAssetId from "../../hooks/useGetDigitalAssetId";

import { DigitalAssetContext } from "../../contexts/continente-credentials/DigitalAssetContext";
import { LangContext } from "../../contexts/continente-credentials/LangContext";
import { UserContext } from "../../contexts/continente-credentials/UserContext";

import { isNumber } from "../../utils/utils";

import { validatePortuguesePhoneNumber } from "../../utils/validatePortuguesePhoneNumber";
import {
  useGoogleRecaptcha,
  getRecaptchaToken,
  siteKey,
} from "../../hooks/continente-credentials/useGoogleRecaptcha";
import { otpService } from "../../services/continente-credentials/common/OtpService";
import { fidoService } from "../../services/continente-credentials/common/Fido2Service";
import { get } from "@github/webauthn-json/browser-ponyfill";

type Form = UserSignIn & FormError;

type toastElementProps = {
  id: number;
  type: string;
  message: string;
};

export default function SignIn() {
  const history = useHistory();
  const { t } = useTranslation();

  const location = useLocation<UserSignInResponse>();
  const digitalAssetContext = useContext(DigitalAssetContext);
  const digitalAssetId = useGetDigitalAssetId();
  const returnUrl = new URLSearchParams(useLocation().search).get("ReturnUrl");

  useGoogleRecaptcha(location.state);

  const [loading, setLoading] = useState(false);
  const [showAlertRealOtps, setShowAlertRealOtps] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const langContext = useContext(LangContext);
  const userContext = useContext(UserContext);
  const [labelEmailOrPhoneNumberVisible, setLabelEmailOrPhoneNumberVisible] =
    useState<boolean>(false);
  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";

  const initialize = useCallback(async (digitalAssetId) => {
    setLoading(true);
    const query = new URLSearchParams(window.location.search);
    const language = query.get("language") || "pt";
    langContext.updateLang({
      ...langContext.lang,
      code: language,
    });

    const digitalAssetResult = await digitalAssetService.getDigitalAsset(
      digitalAssetId || ""
    );

    if (digitalAssetResult.kind === "success") {
      digitalAssetContext.updateDigitalAsset(
        digitalAssetResult.value as DigitalAsset
      );

      if (process.env.REACT_APP_NODE_ENV !== "production") {
        const useFakeOTPResult = await otpService.useFakeOTP();

        if (useFakeOTPResult.kind === "success") {
          if (
            useFakeOTPResult.value.isUsingFakeOtp != null &&
            !useFakeOTPResult.value?.isUsingFakeOtp
          ) {
            showWillSendRealOtps();
          }
        }
      }
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showWillSendRealOtps = () => {
    setShowAlertRealOtps(true);

    setTimeout(() => {
      setShowAlertRealOtps(false);
    }, 3000);
  };

  useEffect(() => {
    initialize(digitalAssetId);
  }, [initialize, digitalAssetId]);

  const updateUserContext = (username: string) => {
    const isEmail = isNaN(Number(username.substring(1)));
    if (isEmail) {
      userContext.updateUser({
        ...userContext.user,
        phoneNumber: "",
        email: username,
        primaryContact: "email",
      });
    } else {
      userContext.updateUser({
        ...userContext.user,
        email: "",
        phoneNumber: username,
        phoneCountryCode: countryCode,
        phoneNumberCode: countryCode,
        primaryContact: "phoneNumber",
      });
    }
  };

  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = formState;

  const handleEmailOrPhoneNumberChange = useCallback(
    (input: string, phoneNumberCode: string) => {
      clearErrors();

      const sanitizedInput = input.replace(/\s+/g, "");

      const { phoneNumberCode: userPhoneNumberCode } = userContext.user;

      const value = isNumber(sanitizedInput)
        ? `${userPhoneNumberCode || phoneNumberCode}${sanitizedInput}`
        : sanitizedInput;

      setPhoneNumber(sanitizedInput);
      setCountryCode(userPhoneNumberCode || phoneNumberCode);

      setValue("username", value);
      setLabelEmailOrPhoneNumberVisible(!!value.length);
    },
    [clearErrors, setValue, userContext]
  );

  const handleFidoLogin = async (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const assertionOptions = await fidoService.getAssertionOptions(
      digitalAssetId || ""
    );

    switch (assertionOptions.kind) {
      case "success": {
        const assertionCredential = await get(assertionOptions.value);
        const response = await fidoService.validateAssertion(
          assertionCredential
        );

        switch (response.kind) {
          case "success": {
            history.push(response.value.nextStep.substring(4));
          }
        }
      }
    }
  };

  useEffect(() => {
    if (userContext.user.primaryContact === "phoneNumber") {
      handleEmailOrPhoneNumberChange(
        userContext.user.phoneNumber,
        userContext.user.phoneNumberCode
      );

      //clear userContext.user.phoneNumberCode
      userContext.user.phoneNumberCode = "";
    } else {
      handleEmailOrPhoneNumberChange(userContext.user.email, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<UserSignIn> = async (data) => {
    setLoading(true);

    try {
      const response = await userSignInService.userSignIn({
        username: data.username,
        clientId: digitalAssetId || "",
        returnUrl,
        phoneNumberCountryCode: isNumber(data.username.replace("+", ""))
          ? countryCode
          : undefined,
        simplePhoneNumber: isNumber(data.username.replace("+", ""))
          ? phoneNumber
          : undefined,
        recaptchaToken: getRecaptchaToken(location.state),
      });

      switch (response.kind) {
        case "success":
          isNumber(data.username.replace("+", ""))
            ? updateUserContext(phoneNumber)
            : updateUserContext(data.username);
          history.push(response.value.nextStep.substring(4), response.value);

          break;
        case "error":
          if (response.code === "100015") {
            setError("submitError", {
              message: t("otp_validation.invalid_nonce"),
            });
            break;
          } else if (response.code === "100016") {
            setError("submitError", {
              message: t("otp_validation.invalid_nonce"),
            });
            break;
          }
          setError("submitError", { message: t("common.unexpected_error") });
          break;
        default:
          setError("submitError", { message: t("common.unexpected_error") });

          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const emailOrPhoneValidator = (register: UseFormRegister<Form>) =>
      register("username", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value:
            /(^\+?\d*$)|(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]*$)/,
          message: t("login_mobile_email.invalid_number_email_input"),
        },
        validate: {
          valid: (phoneNumber) =>
            validatePortuguesePhoneNumber(phoneNumber) ||
            t("common.error_invalid_pt_number"),
        },
      });
    emailOrPhoneValidator(register);
  }, [register, t]);

  return (
    <>
      <div className="row justify-content-center">
        {showAlertRealOtps && (
          <AlertRealOtps
            type={"error"}
            message={"Alerta: envio de SMS e Email ativo!"}
          />
        )}
        <CredentialsHeader
          title={t("login_mobile_email.header_title")}
          subtitle={t("login_mobile_email.header_message")}
          isTextAlignCenter
          showLoading={loading}
        />
      </div>
      <CredentialsForm
        id="RegistrationUser"
        autoComplete="on"
        form={formState}
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row d-flex justify-content-center input-padding-24">
          <UsernameInput
            type="email-phone"
            isFilled={labelEmailOrPhoneNumberVisible}
            isError={errors.username ? true : false}
            errorMsg={errors.username ? errors.username.message : ""}
            onChange={handleEmailOrPhoneNumberChange}
            disabled={loading}
          />
        </div>
        <div
          id="g-recaptcha"
          className="row d-flex justify-content-center input-padding-24"
          data-sitekey={siteKey}
        />
        <ErrorMessage error={errors.submitError} />
        <div className="row d-flex justify-content-center">
          <MantraButton
            text={t("login_mobile_email.button_next_message")}
            type="submit"
            isDisabled={loading}
          />
        </div>
        {digitalAssetContext.digitalAsset.passskeysEnabled && (
          <div className="row d-flex justify-content-center">
            <MantraButton
              text={"Login Passkeys"}
              type="button"
              onClick={handleFidoLogin}
              isDisabled={loading}
            />
          </div>
        )}
        <div className="contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
        <div className="row d-flex flex-column">
          <CredentialsFooter
            contextualInformationVisible
            footerAppsWebsitesIconsVisible
          />
        </div>
      </CredentialsForm>
    </>
  );
}
