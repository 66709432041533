import { HomeInformationResponse } from "../../../models/site-credentials/mobile/HomeInformationResponse";
import { Response } from "../../../models/site-credentials/common/Response";

export interface IHomeInformationService {
  getHomeInfo(
    getHomeInfoUrl: string
  ): Promise<Response<HomeInformationResponse>>;
}

export class HomeInformationService implements IHomeInformationService {
  async getHomeInfo(
    getHomeInfoUrl: string
  ): Promise<Response<HomeInformationResponse>> {
    const resp = await fetch(getHomeInfoUrl, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
      },
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      return {
        kind: "error",
        code: error.code,
        message: error.message,
      };
    } else if (resp.status === 401) {
      return {
        kind: "unauthorized",
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const homeInformationService = new HomeInformationService();
