import { Response } from "../../../models/continente-credentials/common/Response";
import { Config } from "../../../config/Config";
import {
  AuthenticationPublicKeyCredential,
  RegistrationPublicKeyCredential,
  parseCreationOptionsFromJSON,
  parseRequestOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";
import { FidoCredentialRegistrationResponse } from "../../../models/continente-credentials/common/FidoCredentialRegistrationResponse";
import { FidoAssertionResponse } from "../../../models/continente-credentials/common/FidoAssertionResponse";
import { FidoAssertionOptions } from "../../../models/continente-credentials/common/FidoAssertionOptions";

export interface IFido2Service {
  getCredentialOptions(): Promise<Response<CredentialCreationOptions>>;
  registerCredential(
    credential: RegistrationPublicKeyCredential
  ): Promise<Response<FidoCredentialRegistrationResponse>>;
  getAssertionOptions(
    clientId: string
  ): Promise<Response<FidoAssertionOptions>>;
  validateAssertion(
    credential: AuthenticationPublicKeyCredential
  ): Promise<Response<FidoAssertionResponse>>;
}

export class Fido2Service implements IFido2Service {
  async getCredentialOptions(): Promise<Response<CredentialCreationOptions>> {
    const resp = await fetch(
      Config.ContinenteCredentials.fidoCredentialOptionsUrl,
      {
        method: "POST",
      }
    );

    if (resp.ok) {
      const body = await resp.json();

      return {
        kind: "success",
        value: parseCreationOptionsFromJSON({ publicKey: body }),
      };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error.find((c: any) => c.Code != null);
      return {
        kind: "error",
        code: returnedError.Code ? returnedError.Code : 0,
        message: returnedError.message ? returnedError.message : "",
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async registerCredential(
    credential: RegistrationPublicKeyCredential
  ): Promise<Response<FidoCredentialRegistrationResponse>> {
    const resp = await fetch(
      Config.ContinenteCredentials.fidoCredentialRegistrationUrl,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          attestationObject: JSON.stringify(credential),
        }),
      }
    );

    if (resp.ok) {
      const body = await resp.json();

      return {
        kind: "success",
        value: {},
      };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error.find((c: any) => c.Code != null);
      return {
        kind: "error",
        code: returnedError.Code ? returnedError.Code : 0,
        message: returnedError.message ? returnedError.message : "",
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async getAssertionOptions(
    clientId: string
  ): Promise<Response<FidoAssertionOptions>> {
    const resp = await fetch(
      Config.ContinenteCredentials.fidoAssertionOptionsUrl,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          clientId,
        }),
      }
    );

    if (resp.ok) {
      const body = await resp.json();

      return {
        kind: "success",
        value: parseRequestOptionsFromJSON({ publicKey: body }),
      };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error.find((c: any) => c.Code != null);
      return {
        kind: "error",
        code: returnedError.Code ? returnedError.Code : 0,
        message: returnedError.message ? returnedError.message : "",
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async validateAssertion(
    credential: AuthenticationPublicKeyCredential
  ): Promise<Response<FidoAssertionResponse>> {
    const resp = await fetch(
      Config.ContinenteCredentials.fidoAssertionValidationUrl,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          attestationObject: JSON.stringify(credential),
        }),
      }
    );

    if (resp.ok) {
      const body = await resp.json();

      return {
        kind: "success",
        value: body,
      };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error.find((c: any) => c.Code != null);
      return {
        kind: "error",
        code: returnedError.Code ? returnedError.Code : 0,
        message: returnedError.message ? returnedError.message : "",
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const fidoService = new Fido2Service();
