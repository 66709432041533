import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parsePhoneNumber from "libphonenumber-js";

import FaqInformation from "../../components/site-credentials/faq-information/faq-information";
import Header from "../../components/site-credentials/header/Header";

import { DigitalAssetContext } from "../../contexts/site-credentials/DigitalAssetContext";
import { homeInformationService } from "../../services/site-credentials/mobile/HomeInformationService";
import { Config } from "../../config/Config";

type questionProps = {
  question: string;
  answer: string;
  anchor: string;
};

export default function Faq() {
  const digitalAssetContext = useContext(DigitalAssetContext);

  const { hash } = useLocation();
  const { t } = useTranslation();
  const { t: tFaqs } = useTranslation("faqs");

  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState<questionProps[]>([]);
  const [anchorSelected, setAnchorSelected] = useState<string | null>(null);

  let PertoURL = "";

  switch (process.env.REACT_APP_NODE_ENV) {
    case "production":
      PertoURL =
        "https://modelocontinenteb2c.my.salesforce-sites.com/WebformCredenciais";
      break;
    case "development":
      PertoURL =
        "https://modelocontinenteb2c--preprod.sandbox.my.salesforce-sites.com/WebformCredenciais";
      break;
    // case "tst":
    //   PertoURL = "https://modelocontinenteb2c--tst.sandbox.my.salesforce-sites.com/WebformCredenciais"
    //   break;
    default:
      PertoURL =
        "https://modelocontinenteb2c.my.salesforce-sites.com/WebformCredenciais";
  }

  PertoURL += "?tema1=Login+Continente&clientId=";

  const _handlerGetUserInformation = async () => {
    setLoading(true);

    try {
      const response = await homeInformationService.getHomeInfo(
        Config.SiteCredentials.homeInformationUrl
      );

      let returnValue;

      switch (response.kind) {
        case "success":
          returnValue = response.value;
          break;
        default:
          break;
      }

      setLoading(false);
      return returnValue;
    } catch (e) {
      setLoading(false);
    }
  };

  const useScrollToLocation = () => {
    const scrolledRef = React.useRef(false);
    const hashRef = React.useRef(hash);

    React.useEffect(() => {
      if (hash) {
        const auxHash = hash.split("#")[1];
        setAnchorSelected(auxHash as string);

        // We want to reset if the hash has changed
        if (hashRef.current !== hash) {
          hashRef.current = hash;
          scrolledRef.current = false;
        }

        // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
        if (!scrolledRef.current) {
          const id = hash.replace("#", "");
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
            scrolledRef.current = true;
          }
        }
      }
    }, []);
  };

  useEffect(() => {
    const faqs = tFaqs<string, questionProps[]>("faqs", {
      returnObjects: true,
    });

    if (typeof faqs === "object") {
      setQuestions(faqs);
    }

    setLoading(false);
  }, [hash, tFaqs]);

  useScrollToLocation();

  function changeAnchorSelected(newAnchor: string | null) {
    if (newAnchor === anchorSelected) {
      newAnchor = null;
    }

    setAnchorSelected(newAnchor);
  }

  function loadUserSessionData() {
    const fetchData = async () => {
      const userInformation = await _handlerGetUserInformation();

      if (userInformation) {
        if (userInformation?.usernamePhoneNumber != null) {
          const phoneNumber = parsePhoneNumber(
            userInformation?.usernamePhoneNumber
          );

          if (phoneNumber) {
            const sfIframe = document.getElementById(
              "sfIframe"
            ) as HTMLIFrameElement;
            if (sfIframe.contentWindow != null)
              sfIframe.contentWindow.postMessage(
                {
                  nome: "",
                  email: userInformation?.usernameEmail,
                  telemovel: phoneNumber.nationalNumber,
                },
                "*"
              );
          }
        }
      }
    };

    fetchData().catch(console.error);
  }

  return (
    <div className="row justify-content-center">
      <Header
        title={t("site_credentials.helpCenter")}
        showLoading={loading}
        previousStepBackXTimes={1}
      />

      {!loading && questions != null && questions.length > 0 && (
        <div className="faq-container mt-1">
          {questions.map((q, i) => (
            <FaqInformation
              key={i}
              faq={q}
              anchorSelected={anchorSelected}
              onClick={changeAnchorSelected}
            />
          ))}
        </div>
      )}

      <iframe
        id="sfIframe"
        className="faq-perto-webform"
        onLoad={loadUserSessionData}
        src={PertoURL + digitalAssetContext.digitalAsset.clientId}
      ></iframe>
    </div>
  );
}
