export const Config = {
  apiUrl: `${process.env.REACT_APP_BASE_URL}/api/`,
  authorizeUrl: `${process.env.REACT_APP_BASE_URL}/api/auth/authorize`,

  ContinenteCredentials: {
    userRegistrationUrl: `${process.env.REACT_APP_BASE_URL}/api/web/registration/users`,
    otpResendUrl: `${process.env.REACT_APP_BASE_URL}/api/otp/resend`,
    resendLoyaltyOtpUrl: `${process.env.REACT_APP_BASE_URL}/api/otp/loyalty/resend`,
    resendEmailOtpUrl: `${process.env.REACT_APP_BASE_URL}/api/otp/email/resend`,
    passwordRecoverOtpResendUrl: `${process.env.REACT_APP_BASE_URL}/api/password-recover/resend-otp`,
    mobileUserSignInUrl: `${process.env.REACT_APP_BASE_URL}/api/username`,
    digitalAssetUrl: `${process.env.REACT_APP_BASE_URL}/api/digital-asset`,
    authorizeUrl: `${process.env.REACT_APP_BASE_URL}/api/credentials/authorize`,
    fidoCredentialOptionsUrl: `${process.env.REACT_APP_BASE_URL}/api/fido2/credential-options`,
    fidoAssertionOptionsUrl: `${process.env.REACT_APP_BASE_URL}/api/fido2/assertion-options`,
    fidoAssertionValidationUrl: `${process.env.REACT_APP_BASE_URL}/api/fido2/assertion-validate`,
    fidoCredentialRegistrationUrl: `${process.env.REACT_APP_BASE_URL}/api/fido2/credential-register`,
    connectAuthorizeUrl: `${process.env.REACT_APP_BASE_URL}/connect/authorize`,
    UserTestsUrl: `${process.env.REACT_APP_BASE_URL}/api/continente-credentials/users`,
    authorizeSSOUrl: `${process.env.REACT_APP_BASE_URL}/api/credentials/authorize/sso`,
    authorizeSRTUrl: `${process.env.REACT_APP_BASE_URL}/api/credentials/authorize/srt`,
    useFakeOTPUrl: `${process.env.REACT_APP_BASE_URL}/login_continente/v1/otp`,
  },
  SiteCredentials: {
    PasswordDefinitionUrl: `${process.env.REACT_APP_BASE_URL}/api/site-credentials/password/definition/set-password`,
    homeInformationUrl: `${process.env.REACT_APP_BASE_URL}/api/site-credentials`,
    passwordRecoverOtpResendUrl: `${process.env.REACT_APP_BASE_URL}/api/site-credentials/password/recover/resend-otp`,
    MainUrl: `/site-credentials`,
    authorizeUrl: `${process.env.REACT_APP_BASE_URL}/api/sitecredentials/authorize`,
  },
  GoogleRecaptcha: {
    Url: "https://www.google.com/recaptcha/api.js?onload=googleCallback&render=explicit",
  },
};
